exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-download-app-js": () => import("./../../../src/pages/app-download/app.js" /* webpackChunkName: "component---src-pages-app-download-app-js" */),
  "component---src-pages-app-download-index-js": () => import("./../../../src/pages/app-download/index.js" /* webpackChunkName: "component---src-pages-app-download-index-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-declarations-and-certifications-js": () => import("./../../../src/pages/declarations-and-certifications.js" /* webpackChunkName: "component---src-pages-declarations-and-certifications-js" */),
  "component---src-pages-delete-account-js": () => import("./../../../src/pages/delete-account.js" /* webpackChunkName: "component---src-pages-delete-account-js" */),
  "component---src-pages-drafts-1-46-js": () => import("./../../../src/pages/drafts/1_46.js" /* webpackChunkName: "component---src-pages-drafts-1-46-js" */),
  "component---src-pages-drafts-1-47-js": () => import("./../../../src/pages/drafts/1_47.js" /* webpackChunkName: "component---src-pages-drafts-1-47-js" */),
  "component---src-pages-drafts-disclosures-fractional-share-js": () => import("./../../../src/pages/drafts/disclosures/fractional-share.js" /* webpackChunkName: "component---src-pages-drafts-disclosures-fractional-share-js" */),
  "component---src-pages-drafts-disclosures-general-js": () => import("./../../../src/pages/drafts/disclosures/general.js" /* webpackChunkName: "component---src-pages-drafts-disclosures-general-js" */),
  "component---src-pages-drafts-disclosures-index-js": () => import("./../../../src/pages/drafts/disclosures/index.js" /* webpackChunkName: "component---src-pages-drafts-disclosures-index-js" */),
  "component---src-pages-drafts-disclosures-market-volatility-js": () => import("./../../../src/pages/drafts/disclosures/market-volatility.js" /* webpackChunkName: "component---src-pages-drafts-disclosures-market-volatility-js" */),
  "component---src-pages-drafts-our-team-js": () => import("./../../../src/pages/drafts/our-team.js" /* webpackChunkName: "component---src-pages-drafts-our-team-js" */),
  "component---src-pages-drafts-terms-index-js": () => import("./../../../src/pages/drafts/terms/index.js" /* webpackChunkName: "component---src-pages-drafts-terms-index-js" */),
  "component---src-pages-drafts-terms-privacy-policy-en-js": () => import("./../../../src/pages/drafts/terms/privacy-policy-en.js" /* webpackChunkName: "component---src-pages-drafts-terms-privacy-policy-en-js" */),
  "component---src-pages-drafts-terms-privacy-policy-malay-js": () => import("./../../../src/pages/drafts/terms/privacy-policy-malay.js" /* webpackChunkName: "component---src-pages-drafts-terms-privacy-policy-malay-js" */),
  "component---src-pages-drafts-terms-terms-and-conditions-js": () => import("./../../../src/pages/drafts/terms/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-drafts-terms-terms-and-conditions-js" */),
  "component---src-pages-fractional-shares-disclosures-js": () => import("./../../../src/pages/fractional-shares-disclosures.js" /* webpackChunkName: "component---src-pages-fractional-shares-disclosures-js" */),
  "component---src-pages-free-stock-bundles-august-2024-js": () => import("./../../../src/pages/free-stock-bundles-august-2024.js" /* webpackChunkName: "component---src-pages-free-stock-bundles-august-2024-js" */),
  "component---src-pages-free-stock-bundles-july-2024-js": () => import("./../../../src/pages/free-stock-bundles-july-2024.js" /* webpackChunkName: "component---src-pages-free-stock-bundles-july-2024-js" */),
  "component---src-pages-gti-app-js": () => import("./../../../src/pages/gti-app.js" /* webpackChunkName: "component---src-pages-gti-app-js" */),
  "component---src-pages-id-1010-promo-index-js": () => import("./../../../src/pages/id/1010promo/index.js" /* webpackChunkName: "component---src-pages-id-1010-promo-index-js" */),
  "component---src-pages-id-app-download-app-js": () => import("./../../../src/pages/id/app-download/app.js" /* webpackChunkName: "component---src-pages-id-app-download-app-js" */),
  "component---src-pages-id-app-download-index-js": () => import("./../../../src/pages/id/app-download/index.js" /* webpackChunkName: "component---src-pages-id-app-download-index-js" */),
  "component---src-pages-id-delete-account-js": () => import("./../../../src/pages/id/delete-account.js" /* webpackChunkName: "component---src-pages-id-delete-account-js" */),
  "component---src-pages-id-etf-index-js": () => import("./../../../src/pages/id/etf/index.js" /* webpackChunkName: "component---src-pages-id-etf-index-js" */),
  "component---src-pages-id-fee-index-js": () => import("./../../../src/pages/id/fee/index.js" /* webpackChunkName: "component---src-pages-id-fee-index-js" */),
  "component---src-pages-id-free-stock-bundles-august-2024-index-js": () => import("./../../../src/pages/id/free-stock-bundles-august-2024/index.js" /* webpackChunkName: "component---src-pages-id-free-stock-bundles-august-2024-index-js" */),
  "component---src-pages-id-free-stock-bundles-july-2024-index-js": () => import("./../../../src/pages/id/free-stock-bundles-july-2024/index.js" /* webpackChunkName: "component---src-pages-id-free-stock-bundles-july-2024-index-js" */),
  "component---src-pages-id-fund-for-fun-jul-2024-index-js": () => import("./../../../src/pages/id/fund-for-fun-jul-2024/index.js" /* webpackChunkName: "component---src-pages-id-fund-for-fun-jul-2024-index-js" */),
  "component---src-pages-id-independence-day-aug-2024-index-js": () => import("./../../../src/pages/id/independence-day-aug-2024/index.js" /* webpackChunkName: "component---src-pages-id-independence-day-aug-2024-index-js" */),
  "component---src-pages-id-index-js": () => import("./../../../src/pages/id/index.js" /* webpackChunkName: "component---src-pages-id-index-js" */),
  "component---src-pages-id-karir-detail-js": () => import("./../../../src/pages/id/karir/detail.js" /* webpackChunkName: "component---src-pages-id-karir-detail-js" */),
  "component---src-pages-id-karir-index-js": () => import("./../../../src/pages/id/karir/index.js" /* webpackChunkName: "component---src-pages-id-karir-index-js" */),
  "component---src-pages-id-legal-index-js": () => import("./../../../src/pages/id/legal/index.js" /* webpackChunkName: "component---src-pages-id-legal-index-js" */),
  "component---src-pages-id-legal-privacy-policy-page-index-js": () => import("./../../../src/pages/id/legal/privacy-policy-page/index.js" /* webpackChunkName: "component---src-pages-id-legal-privacy-policy-page-index-js" */),
  "component---src-pages-id-novemberpaydayspecial-index-js": () => import("./../../../src/pages/id/novemberpaydayspecial/index.js" /* webpackChunkName: "component---src-pages-id-novemberpaydayspecial-index-js" */),
  "component---src-pages-id-octobercashbackblitz-index-js": () => import("./../../../src/pages/id/octobercashbackblitz/index.js" /* webpackChunkName: "component---src-pages-id-octobercashbackblitz-index-js" */),
  "component---src-pages-id-options-trading-index-js": () => import("./../../../src/pages/id/options-trading/index.js" /* webpackChunkName: "component---src-pages-id-options-trading-index-js" */),
  "component---src-pages-id-our-team-js": () => import("./../../../src/pages/id/our-team.js" /* webpackChunkName: "component---src-pages-id-our-team-js" */),
  "component---src-pages-id-panduan-investasi-saham-as-indeks-js": () => import("./../../../src/pages/id/panduan-investasi-saham-as/indeks.js" /* webpackChunkName: "component---src-pages-id-panduan-investasi-saham-as-indeks-js" */),
  "component---src-pages-id-panduan-investasi-saham-as-index-js": () => import("./../../../src/pages/id/panduan-investasi-saham-as/index.js" /* webpackChunkName: "component---src-pages-id-panduan-investasi-saham-as-index-js" */),
  "component---src-pages-id-panduan-investasi-saham-as-kenapa-saham-as-js": () => import("./../../../src/pages/id/panduan-investasi-saham-as/kenapa-saham-as.js" /* webpackChunkName: "component---src-pages-id-panduan-investasi-saham-as-kenapa-saham-as-js" */),
  "component---src-pages-id-panduan-investasi-saham-as-saham-js": () => import("./../../../src/pages/id/panduan-investasi-saham-as/saham.js" /* webpackChunkName: "component---src-pages-id-panduan-investasi-saham-as-saham-js" */),
  "component---src-pages-id-panduan-investasi-saham-as-strategi-investasi-js": () => import("./../../../src/pages/id/panduan-investasi-saham-as/strategi-investasi.js" /* webpackChunkName: "component---src-pages-id-panduan-investasi-saham-as-strategi-investasi-js" */),
  "component---src-pages-id-powerupoctober-index-js": () => import("./../../../src/pages/id/powerupoctober/index.js" /* webpackChunkName: "component---src-pages-id-powerupoctober-index-js" */),
  "component---src-pages-id-prestige-index-js": () => import("./../../../src/pages/id/prestige/index.js" /* webpackChunkName: "component---src-pages-id-prestige-index-js" */),
  "component---src-pages-id-promo-index-js": () => import("./../../../src/pages/id/promo/index.js" /* webpackChunkName: "component---src-pages-id-promo-index-js" */),
  "component---src-pages-id-referral-index-js": () => import("./../../../src/pages/id/referral/index.js" /* webpackChunkName: "component---src-pages-id-referral-index-js" */),
  "component---src-pages-id-referral-qrcode-js": () => import("./../../../src/pages/id/referral/qrcode.js" /* webpackChunkName: "component---src-pages-id-referral-qrcode-js" */),
  "component---src-pages-id-ridetheelectionwave-index-js": () => import("./../../../src/pages/id/ridetheelectionwave/index.js" /* webpackChunkName: "component---src-pages-id-ridetheelectionwave-index-js" */),
  "component---src-pages-id-rm-fany-js": () => import("./../../../src/pages/id/rm-fany.js" /* webpackChunkName: "component---src-pages-id-rm-fany-js" */),
  "component---src-pages-id-rm-fika-js": () => import("./../../../src/pages/id/rm-fika.js" /* webpackChunkName: "component---src-pages-id-rm-fika-js" */),
  "component---src-pages-id-rm-maria-js": () => import("./../../../src/pages/id/rm-maria.js" /* webpackChunkName: "component---src-pages-id-rm-maria-js" */),
  "component---src-pages-id-rm-tiara-js": () => import("./../../../src/pages/id/rm-tiara.js" /* webpackChunkName: "component---src-pages-id-rm-tiara-js" */),
  "component---src-pages-id-safe-and-secure-index-js": () => import("./../../../src/pages/id/safe-and-secure/index.js" /* webpackChunkName: "component---src-pages-id-safe-and-secure-index-js" */),
  "component---src-pages-id-september-prosper-2024-index-js": () => import("./../../../src/pages/id/september-prosper-2024/index.js" /* webpackChunkName: "component---src-pages-id-september-prosper-2024-index-js" */),
  "component---src-pages-id-tradersleague-index-js": () => import("./../../../src/pages/id/tradersleague/index.js" /* webpackChunkName: "component---src-pages-id-tradersleague-index-js" */),
  "component---src-pages-id-tradersleague-registration-index-js": () => import("./../../../src/pages/id/tradersleague/registration/index.js" /* webpackChunkName: "component---src-pages-id-tradersleague-registration-index-js" */),
  "component---src-pages-id-welcome-rewards-index-js": () => import("./../../../src/pages/id/welcome-rewards/index.js" /* webpackChunkName: "component---src-pages-id-welcome-rewards-index-js" */),
  "component---src-pages-id-whats-new-index-js": () => import("./../../../src/pages/id/whats-new/index.js" /* webpackChunkName: "component---src-pages-id-whats-new-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-discord-js": () => import("./../../../src/pages/join-discord.js" /* webpackChunkName: "component---src-pages-join-discord-js" */),
  "component---src-pages-join-telegram-js": () => import("./../../../src/pages/join-telegram.js" /* webpackChunkName: "component---src-pages-join-telegram-js" */),
  "component---src-pages-legal-html-privacy-policy-en-js": () => import("./../../../src/pages/legal/html/privacy-policy-en.js" /* webpackChunkName: "component---src-pages-legal-html-privacy-policy-en-js" */),
  "component---src-pages-legal-index-js": () => import("./../../../src/pages/legal/index.js" /* webpackChunkName: "component---src-pages-legal-index-js" */),
  "component---src-pages-legal-w-8-js": () => import("./../../../src/pages/legal/w8.js" /* webpackChunkName: "component---src-pages-legal-w-8-js" */),
  "component---src-pages-partner-index-js": () => import("./../../../src/pages/partner/index.js" /* webpackChunkName: "component---src-pages-partner-index-js" */),
  "component---src-pages-partner-qrcode-index-js": () => import("./../../../src/pages/partner/qrcode/index.js" /* webpackChunkName: "component---src-pages-partner-qrcode-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-referral-index-js": () => import("./../../../src/pages/referral/index.js" /* webpackChunkName: "component---src-pages-referral-index-js" */),
  "component---src-pages-referral-qrcode-js": () => import("./../../../src/pages/referral/qrcode.js" /* webpackChunkName: "component---src-pages-referral-qrcode-js" */),
  "component---src-pages-stock-gift-index-js": () => import("./../../../src/pages/stockGift/index.js" /* webpackChunkName: "component---src-pages-stock-gift-index-js" */),
  "component---src-pages-unsubscribe-js": () => import("./../../../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */),
  "component---src-pages-welcome-rewards-js": () => import("./../../../src/pages/welcome-rewards.js" /* webpackChunkName: "component---src-pages-welcome-rewards-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blogs-js": () => import("./../../../src/templates/blogs.js" /* webpackChunkName: "component---src-templates-blogs-js" */),
  "component---src-templates-gti-whats-new-js": () => import("./../../../src/templates/gti-whats-new.js" /* webpackChunkName: "component---src-templates-gti-whats-new-js" */)
}

